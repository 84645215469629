import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import useProfile from 'src/hooks/useProfile';
import { getProfileMenu } from 'src/common/accountItems';
import useNotification from '../../hooks/useNotification';

const AccountHeaderDesktopLazy = dynamic(() =>
  import('./AccountHeaderDesktop.jsx'),
);
const AccountHeaderMobileLazy = dynamic(() =>
  import('./AccountHeaderMobile.jsx'),
);

const AccountHeader = ({ isMobile, logout, onRegister, onLogin, data }) => {
  const ntf = useNotification(data?.me);
  const router = useRouter();
  const { status } = useProfile();
  const profileMenuItems = getProfileMenu(status);

  const props = {
    logout,
    onRegister,
    onLogin,
    data,
    ...ntf,
    router,
    profileMenuItems,
  };
  return (
    <Flex alignItems='center'>
      {isMobile ? (
        <AccountHeaderMobileLazy {...props} />
      ) : (
        <AccountHeaderDesktopLazy {...props} />
      )}
    </Flex>
  );
};

AccountHeader.propTypes = {
  isMobile: PropTypes.bool,
  logout: PropTypes.func,
  onRegister: PropTypes.func,
  onLogin: PropTypes.func,
  data: PropTypes.object,
};

export default AccountHeader;
