import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';
import NextLink from '../../components/NextLink';
import { getPathName } from '../../utils/urlControl';

const MenuItem = ({
  dataTestId,
  isMobile,
  to,
  value,
  setOpen,
  children,
  activeColor = 'typography.primary',
  color = 'typography.secondary',
  ...other
}) => {
  const router = useRouter();
  const pathname = getPathName(router?.pathname);
  return isMobile ? (
    <Box
      data-testid={dataTestId}
      bg={pathname === to && 'brand.primaryGradient'}
      borderRadius={pathname === to && 'lg'}
      fontWeight={pathname === to ? 'semibold' : 'normal'}
      color={pathname === to ? 'white' : color}
      _hover={
        pathname !== to && {
          color: 'typography.primary',
          textShadow: '0 0 black',
          transition: '0.1s',
        }
      }
      onClick={() => setOpen && setOpen(null)}
      textAlign='center'
      {...other}
    >
      <NextLink
        href={to}
        style={{
          padding: '8px',
          width: 'inherit',
          display: 'block',
        }}
      >
        {value || children}
      </NextLink>
    </Box>
  ) : (
    <Box
      data-testid={dataTestId}
      mr={4}
      fontWeight={pathname === to ? 'semibold' : 'normal'}
      color={pathname === to ? activeColor : color}
      _hover={
        pathname !== to && {
          color: 'typography.primary',
          textShadow: '0 0 black',
          transition: '0.1s',
        }
      }
      onClick={() => setOpen && setOpen(null)}
      {...other}
    >
      <NextLink href={to}>{value}</NextLink>
    </Box>
  );
};

MenuItem.propTypes = {
  setOpen: PropTypes.func,
  isMobile: PropTypes.bool,
  to: PropTypes.string,
  value: PropTypes.string,
};
export default MenuItem;
