import { tabKeys } from '../components/ProfileTab/common';
import { userTypes } from './constants';

const { common, orders, tasks } = tabKeys;

export const ordererProfileMenu = [
  {
    id: 'my-orders-orderer',
    name: 'my-orders',
    to: '/profile/orders',
    type: orders,
  },
  {
    id: 'settings',
    name: 'settings',
    to: '/profile/settings',
  },
];

export const executorProfileMenu = [
  {
    id: 'profile',
    name: 'profile',
    to: '/profile',
    type: common,
  },
  {
    id: 'my-orders-orderer',
    name: 'my-orders-orderer',
    to: '/profile?index=orders&scroll=true',
    type: orders,
  },
  {
    id: 'my-tasks-executor',
    name: 'my-tasks-executor',
    to: '/profile?index=tasks&scroll=true',
    type: tasks,
  },
  {
    id: 'settings',
    name: 'settings',
    to: '/profile/?index=settings&scroll=true',
  },
];

export const getProfileMenu = status =>
  status === userTypes.ORDERER ? ordererProfileMenu : executorProfileMenu;
