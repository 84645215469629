import Eventsource from 'eventsource';
import config from '../config';
import { setInitNot, mapNotifications } from '../store/notification';
import { throwErrorSentry } from './throwErrorSentry';
import { clearAuthState, saveAuthState } from './urqlClient';
import { getRefreshToken, getRequest } from './urqlClient/auth';

const getNewToken = async pass => {
  try {
    const token = pass ? 'pass' : getRefreshToken();
    if (!token) return '';
    const {
      data: { refreshToken, accessToken },
    } = await getRequest(token);
    saveAuthState({
      token: accessToken,
      refreshToken,
    });
    return accessToken;
  } catch (err) {
    if (err?.message !== 'Network Error') {
      throwErrorSentry(err);
      clearAuthState();
    }
    return '';
  }
};

let events;
let eventsCount = 0;
const retryCount = 20;

const subscribeToNotification = async (ordererDispatch, executorDispatch) => {
  const accessToken = await getNewToken();
  if (!accessToken || events) return;
  events = new Eventsource(`${config.api}/notifications`, {
    headers: { authorization: accessToken },
  });

  events.onmessage = event => {
    const notifications = JSON.parse(event.data);

    if (!notifications) return;
    const allNotifications = mapNotifications(notifications);
    setInitNot(executorDispatch, allNotifications.executor);
    setInitNot(ordererDispatch, allNotifications.orderer);
  };
  // if expired then restart
  events.onerror = async () => {
    eventsCount += 1;
    events?.close();
    events = undefined;
    if (eventsCount < retryCount) {
      await new Promise(res => setTimeout(res, 2000));
      subscribeToNotification(ordererDispatch, executorDispatch);
    }
  };
};

let guestEvents;
let guestEventsCount = 0;
/**
 * subscribes to events from server by a random id without login
 * @param {string} id - unique id to identify user on backend
 * @param {function} handler
 */
const subscribeToGuestNotification = (id, handler) => {
  // TODO - должен при повторном вызове просто добавлять обработчики и возвращать id
  // для того чтобы использовать один эндпоинт в разныз местах и определять обработчик
  //  по тексту сообщения
  guestEvents = new Eventsource(`${config.api}/open-notifications`, {
    withCredentials: true,
    headers: { clientId: id, 'subscription-type': 'check-auth' },
  });
  guestEvents.onmessage = event => {
    const notifications = JSON.parse(event.data);

    if (!notifications) return;
    handler(notifications);
  };
  // if expired then restart
  guestEvents.onerror = async () => {
    guestEvents?.close();
    guestEvents = undefined;
    guestEventsCount += 1;

    if (guestEventsCount < retryCount) {
      await new Promise(res => setTimeout(res, 2000));
      subscribeToGuestNotification(id, handler);
    }
  };

  // реализовать unsubscribe - должен удалять обработчики и если нет обработчиков, то .close();
  return () => {
    guestEvents?.close();
    guestEvents = undefined;
    guestEventsCount = 0;
  };
};

export { subscribeToNotification, subscribeToGuestNotification };
