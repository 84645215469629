import React, { useContext } from 'react';
import { Box, Flex, Heading, Img, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { routerPush } from 'src/utils/urlControl';
import settings from 'src/common/settings';
import MenuItem from '../../commonComponents/MenuItem';
import AccountHeader from '../AccountHeader';
import DefaultContainer from '../../layouts/DefaultContainer';
import { size } from '../../theme/constants';
import goBack from '../../utils/goBack';
import { commonStore } from '../../store/storeProvider';
import NextLink from '../NextLink';

const HeaderDesktop = ({
  logout,
  onRegister,
  onLogin,
  data,
  navigationItems,
  isShowPhone,
}) => {
  const {
    brand,
    phone,
    phoneValidation: { phonePrefix, phoneFormat },
  } = settings;
  const { t } = useTranslation(['common']);
  const {
    common: { filterQueryString },
  } = useContext(commonStore);
  const router = useRouter();

  return (
    <DefaultContainer
      container={{
        as: 'header',
        position: 'fixed',
        left: '0',
        top: '0',
        bg: 'white',
        zIndex: '1300',
        boxShadow: 'lg',
      }}
      pb={0}
      pt={0}
    >
      <Flex
        w='100%'
        h={size.header}
        boxSizing='border-box'
        justifyContent='space-between'
      >
        {router?.query?.from === 'search' ? (
          <Flex alignItems='center' color='typography.primary'>
            <Img
              w={8}
              h={8}
              mr={3}
              src='/deployment/icons/arrowBackDesktop.svg'
              cursor='pointer'
              onClick={() => goBack(router, filterQueryString)}
            />
            <Heading
              size='sm'
              cursor='pointer'
              onClick={() => goBack(router, filterQueryString)}
            >
              {t('button.back')}
            </Heading>
          </Flex>
        ) : (
          <Flex alignItems='center'>
            <Img
              w={9}
              h={9}
              mr={5}
              src={'/deployment/icons/logo.svg'}
              cursor='pointer'
              alt={t('menu.home')}
              data-testid='logo'
              onClick={() => routerPush(router, '/')}
            />
            <Heading
              as='span'
              display={['none', 'none', 'none', 'block']}
              size='md'
              cursor='pointer'
              onClick={() => routerPush(router, '/')}
            >
              {brand}
            </Heading>
          </Flex>
        )}
        <Box as='nav'>
          <Flex
            alignItems='center'
            justifyContent='space-between'
            h='100%'
            as='ul'
          >
            {navigationItems.map((item, index) => (
              <MenuItem
                key={index}
                to={item.to}
                value={t(`menu.${item.name}`)}
                isMobile={false}
                data-testid={`header-nav-el-${index}`}
                color='typography.primary'
              />
            ))}
          </Flex>
        </Box>
        <Flex alignItems='center' justifyContent='center'>
          {isShowPhone ? (
            <NextLink href={`tel:${phonePrefix}${phone}`}>
              <Text fontWeight='bold' mr={5}>
                {`${phonePrefix} ${phoneFormat(phone)}`}
              </Text>
            </NextLink>
          ) : (
            <AccountHeader
              isMobile={false}
              data={data}
              onRegister={onRegister}
              onLogin={onLogin}
              logout={logout}
            />
          )}
        </Flex>
      </Flex>
    </DefaultContainer>
  );
};

HeaderDesktop.propTypes = {
  isMobile: PropTypes.bool,
  logout: PropTypes.func,
  onRegister: PropTypes.func,
  onLogin: PropTypes.func,
  data: PropTypes.object,
  navigationItems: PropTypes.array,
};

export default HeaderDesktop;
