import { useEffect, useContext } from 'react';

import { useQuery } from 'urql';
import {
  executorNtfStore,
  ordererNtfStore,
  setInitNot,
  setNot,
  mapNotifications,
} from '../store/notification';
import { subscribeToNotification } from '../utils/helpersNotification';
import { NOTIFICATIONS } from '../gql/query';

// const getNotifications = () => ({
//   executor: {
//     '1f5bc174-25d7-41c8-8db9-2505147588b3': [{
//       id: 'c1bba544asdee3beeweba91', type: NEW_MESSAGE, isPopUp: false, status: OPEN,
//     }],
//     '656062e7-e1a2-452b-9351-35ce41c5ee77': [{
//       id: '656062e7asdasd1-35ce41wec5ee77',
// type: ORDER_APPROVED_BY_ORDERER, isPopUp: true, status: DONE,
//     }],
//     '7debcdc4-b41c-4ad4-8ccd-f6a47c45e68d': [{
//       id: '7debcwec4-b41casdasdccd-f6a47c45e68d',
// type: NEW_MESSAGE, isPopUp: false, status: IN_PROGRESS,
//     }],
//   },
//   orderer: {
//     '75a3be72-808c-409e-ad29-94a0dfcaf50d': [{
//       id: '75ae72-808asdasdfcaf50d',
// type: ORDER_COMPLETE_BY_EXECUTOR, isPopUp: false, status: IN_PROGRESS,
//     }],
//     '8a3d1be1-7d96-4d6e-b984-f9225aafd715': [{
//       id: '8aeee-b984-f9225aafed715', type: FEEDBACK_FROM_EXECUTOR, isPopUp: true, status: DONE,
//     }],
//     'bcadf46b-12c8-4aeb-b66d-64afc14613d0': [
//       {
//         id: 'asdasdafc14613d0',
//         type: NEW_EXECUTOR,
//         isPopUp: true,
//         status: OPEN,
//         payload: {
//           tenderId: '99f8f47d-9549-483a-975a-a8db7766c663',
//         },
//       },
//       {
//         id: 'bcadf4asdasdb6sd-64afc14613d0',
//         type: NEW_MESSAGE,
//         isPopUp: false,
//         status: OPEN,
//         payload: {
//           tenderId: 'cb062c7a-c2ba-494e-989e-4f44454e4cf1',
//         },
//       },
//       {
//         id: 'bcadf46b-asdasd64afc14s613d0',
//         type: NEW_EXECUTOR,
//         isPopUp: false,
//         status: OPEN,
//         payload: {
//           tenderId: 'cb062c7a-c2ba-494e-989e-4f44454e4cf1',
//         },
//       }],
//   },
// });

const unsubscribeToNotification = () => {};

/**
 * Subscribe to the event server and send incoming notifications to the store
 */
const useNotification = me => {
  const { executorNtf, dispatch: executorDispatch } =
    useContext(executorNtfStore);
  const { ordererNtf, dispatch: ordererDispatch } = useContext(ordererNtfStore);

  const [result, reexecuteQuery] = useQuery({
    query: NOTIFICATIONS,
    pause: !me,
    requestPolicy: 'network-only',
  });

  const refetch = () => {
    // Refetch the query and skip the cache
    reexecuteQuery({ requestPolicy: 'network-only' });
  };

  useEffect(() => {
    if (result.fetching) return;
    const allNotifications = mapNotifications(result?.data?.notifications);
    setInitNot(executorDispatch, allNotifications.executor);
    setInitNot(ordererDispatch, allNotifications.orderer);
  }, [result.fetching, reexecuteQuery]);

  useEffect(() => {
    (async () => {
      // read all notification from server and save them into store
      subscribeToNotification(ordererDispatch, executorDispatch, setNot);
      // refetch after login
      if (me && refetch) refetch();
    })();

    // subscribe to the server to listen to new notification
    return unsubscribeToNotification;
  }, [me]);

  const allNtf =
    (executorNtf?.ntf && Object.keys(executorNtf?.ntf).length) ||
    0 + (ordererNtf?.ntf && Object.keys(ordererNtf?.ntf).length) ||
    0;
  return {
    executorNtf,
    ordererNtf,
    allNtf,
  };
};

export default useNotification;
