import { useEffect } from 'react';

export const useExtractParam = (router, setTabIndex) => {
  useEffect(() => {
    if (router.asPath) {
      const query = router.asPath.match(/index=[0-9]/);
      if (query) {
        const index = query[0].replace('index=', '');
        setTabIndex(parseInt(index, 10));
      }
    }
  }, []);
};

export const tabKeys = {
  common: 'common',
  specs: 'specs',
  orders: 'orders',
  tasks: 'tasks',
  settings: 'settings',
};

export const subTabKeys = {
  openOrders: 'openOrders',
  duringOrders: 'duringOrders',
  closedOrders: 'closedOrders',
  openTasks: 'openTasks',
  duringTasks: 'duringTasks',
  closedTasks: 'closedTasks',
};
